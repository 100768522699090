<template>
  <Dialog>
    <div class="px-md pb-md flex flex-col max-h-[92vh]">
      <div class="flex justify-center mb-sm mt-md">
        <HeadlineDynamic
          headline="dialog.myproducts.deleteFavList.headline"
          level="h4"
        />
      </div>
      <div class="overflow-auto h-fit">
        <div v-if="errors.length" class="mb-md">
          <UnknownError />
        </div>
        <div class="text-center mb-md">
          {{ t('dialog.myproducts.deleteFavList.text') }}
        </div>
        <ButtonBasic
          classes="w-full px-sm md:px-0"
          :label="t('myProducts.favoriteLists.form.delete')"
          :type="EButtonType.SUBMIT"
          :btn-style="EButtonStyle.CTA"
          bg-color="var(--vdv-btn-base)"
          :disabled="isLoading"
          full-width
          @click="deleteFavList()"
        ></ButtonBasic>
        <div
          class="flex items-center justify-center w-full gap-2 cursor-pointer text-text-base mt-md"
          @click="dialogStore.closeDialog()"
        >
          <span class="anim__underline-link vdv-anim-link">
            {{ t('user.account.addresses.deleteDialog.cancel') }}
          </span>
        </div>
      </div>
    </div>
  </Dialog>
</template>
<script setup lang="ts">
import UnknownError from '~/components/formFields/errors/unknown/unknownError.vue';
import Dialog from '@/components/dialog/components/dialogFrame/empty/dialogFrame.vue';
import { useDeleteFavList } from '../useDeleteFavList';
import { EButtonStyle, EButtonType } from '~~/src/@types/basic-button';
import ButtonBasic from '~~/src/components/components/core/basic-button/vdv/basic-button.vue';
import HeadlineDynamic from '@/components/components/core/headline-dynamic/vdv/headline-dynamic.vue';

const { deleteFavList, errors, dialogStore, isLoading } = useDeleteFavList();
const { t } = useTrans();
</script>
